export const AccessTokenType = {
    JWT: 'JWT',
}

export class AccessToken {
    type: string
    value: string
    constructor(type: string, value: string) {
        this.type = type
        this.value = value
    }
}
