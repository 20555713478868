import Router from 'next/router'
import { AccessToken, AccessTokenType } from '../models/AccessTokens'
import { Apps } from '../utils'
import { PartnerResponse } from './responses'
import { signOut, signIn } from 'next-auth/react'

export const getAccessToken = (
    accessTokenType: string
): AccessToken | undefined => {
    const item = window.sessionStorage.getItem(accessTokenType)
    if (item) return JSON.parse(item) as AccessToken
}

export const getPartner = (): PartnerResponse | undefined => {
    const item = window.sessionStorage.getItem('partner')
    if (item) return JSON.parse(item) as PartnerResponse
}

export const handleAuthenticationError = (app: Apps) => {
    Object.keys(AccessTokenType).forEach((type) =>
        sessionStorage.removeItem(type)
    )
    if (app === 'DevPortal') {
        signIn('keycloak', { callbackUrl: '/portal/callback' })
    } else {
        Router.push('/internal/login')
    }
}

export const logout = (app: Apps = 'Internal') => {
    Object.keys(AccessTokenType).forEach((type) =>
        sessionStorage.removeItem(type)
    )
    if (app === 'DevPortal') {
        signOut({ callbackUrl: '/' })
    } else {
        Router.push('/internal/signedout')
    }
}

const Auth = {
    getAccessToken,
    getPartner,
    logout,
}

export default Auth
