import React, { useState, useCallback } from 'react'
import { Notification } from '@epidemicsound/design-system'

const NotificationType = Notification.TYPES

export interface Message {
    messages: string[]
    type: string
}

export interface MessageContextProps {
    message?: Message | null
    addError: (...errors: string[]) => void
    addInfo: (...infos: string[]) => void
    addSuccess: (...success: string[]) => void
    removeMessage: () => void
}

export const MessageContext = React.createContext<
    MessageContextProps | undefined
>(undefined)

interface MessageProviderProps {
    children: React.ReactNode
}

const MessageProvider = ({ children }: MessageProviderProps) => {
    const [message, setMessage] = useState<Message | null>()
    const removeMessage = () => setMessage(null)
    const addError = (errors: string[]) =>
        setMessage({ messages: errors, type: NotificationType.ERROR })
    const addInfo = (infos: string[]) =>
        setMessage({ messages: infos, type: NotificationType.INFO })
    const addSuccess = (success: string[]) =>
        setMessage({ messages: success, type: NotificationType.SUCCESS })

    const contextValue = {
        message,
        addError: useCallback((...errors) => addError(errors), []),
        addInfo: useCallback((...infos) => addInfo(infos), []),
        addSuccess: useCallback(
            (...successfull) => addSuccess(successfull),
            []
        ),
        removeMessage: useCallback(() => removeMessage(), []),
    } as MessageContextProps

    return (
        <MessageContext.Provider value={contextValue}>
            {children}
        </MessageContext.Provider>
    )
}

export default MessageProvider
