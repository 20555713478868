import React, { useState } from 'react'
import { MetadataTrackResponse } from 'api/responses'
import { PlaybackStatus } from '../../utils'
import { QueuedMetadataTrackResponse } from '../playerqueueprovider'

export interface ActiveTrackContextProps {
    status: PlaybackStatus
    activeTrack?: QueuedMetadataTrackResponse
    setStatus: (status: PlaybackStatus) => void
    playTrack: (track: QueuedMetadataTrackResponse) => void
    stopTrack: () => void
    pauseTrack: () => void
}

export const ActiveTrackContext = React.createContext<
    ActiveTrackContextProps | undefined
>(undefined)

interface ActiveTrackProviderProps {
    children: React.ReactNode
}

const ActiveTrackProvider = ({ children }: ActiveTrackProviderProps) => {
    const [activeTrack, setActiveTrack] = useState<MetadataTrackResponse>()
    const [status, setStatus] = useState<PlaybackStatus>('pause')

    const playTrack = (track: MetadataTrackResponse) => {
        setActiveTrack(track)
        setStatus('play')
    }

    const stopTrack = () => {
        setActiveTrack(undefined)
        setStatus('pause')
    }

    const pauseTrack = () => {
        setStatus('pause')
    }

    const contextValue = {
        status,
        activeTrack,
        setStatus,
        playTrack,
        stopTrack,
        pauseTrack,
    } as ActiveTrackContextProps

    return (
        <ActiveTrackContext.Provider value={contextValue}>
            {children}
        </ActiveTrackContext.Provider>
    )
}

export default ActiveTrackProvider
