import React, { useState } from 'react'

export interface LoadingContextProps {
    isLoading: boolean
    setLoading: (isLoading: boolean) => void
}

export const LoadingContext = React.createContext<
    LoadingContextProps | undefined
>(undefined)

interface LoadingProviderProps {
    children: React.ReactNode
    initialLoading: boolean
}

const LoadingProvider = ({
    initialLoading,
    children,
}: LoadingProviderProps) => {
    const [isLoading, setLoading] = useState(initialLoading)
    const contextValue = { isLoading, setLoading } as LoadingContextProps

    return (
        <LoadingContext.Provider value={contextValue}>
            {children}
        </LoadingContext.Provider>
    )
}

export default LoadingProvider
