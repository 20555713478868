import { Endpoint } from '.'
import {
    getCurrentOrDefault,
    setCurrent,
    UrlByEnvironment,
} from 'models/Environments'

export const Headers = {
    Accept: 'Accept',
    Authorization: 'Authorization',
    ContentType: 'Content-Type',
    AppId: 'App-Identity',
}

export const Methods = {
    Get: 'GET',
    Post: 'POST',
    Patch: 'PATCH',
    Delete: 'DELETE',
    Put: 'PUT',
}

export const ResponseStatuses = {
    Unauthorized: 401,
    BadRequest: 400,
}

export const ContentTypes = {
    ApplicationJson: 'application/json',
}

export const getBearerTokenAuthenticationHeader = (
    accessToken: string
): { [key: string]: string } => {
    return { [Headers.Authorization]: `Bearer ${accessToken}` }
}

export const getBaseUrl = (): string | undefined => {
    const env = getCurrentOrDefault()
    if (env) {
        setCurrent(env)
        const url = UrlByEnvironment.find((config) => config.value === env)?.url
        if (!url) {
            throw new Error(
                `Invalid environment setting! Please clear local storage!`
            )
        }
        return url
    }
    throw new Error(`Missing environment setting!`)
}

export const getApiUrl = (
    endpoint: Endpoint,
    pathParams?: { [key: string]: string },
    queryParams?: { [key: string]: string | boolean }
): URL => {
    let path

    if (pathParams) {
        path = endpoint.withPathParams(pathParams).toString()
    } else {
        path = endpoint.toString()
    }

    const url = new URL(path, getBaseUrl())

    if (queryParams) {
        Object.keys(queryParams).forEach((name: string) =>
            url.searchParams.append(name, queryParams[name].toString())
        )
    }
    return url
}

export const padSlash = (str: string): string => {
    if (!str.startsWith('/')) return `/${str}`
    return str
}
