import { useRouter as useNextRouter } from 'next/router'
import { useState, useContext, useCallback, useRef, useEffect } from 'react'
import { padSlash } from '../api/utils'
import { TabListItemProps } from 'components/tab/TabList'
import {
    ActiveTrackContext,
    ActiveTrackContextProps,
} from 'providers/activetrackprovider'
import {
    InternalAuthenticationContext,
    InternalAuthenticationContextProps,
} from 'providers/authenticationprovider/internal'
import {
    ContentfulPreviewContext,
    ContentfulPreviewContextProps,
} from 'providers/contentfulpreviewprovider'
import {
    EnvironmentContext,
    EnvironmentContextProps,
} from 'providers/environmentprovider'
import { LoadingContext, LoadingContextProps } from 'providers/loadingprovider'
import { MessageContext, MessageContextProps } from 'providers/messageprovider'
import {
    PageModeContext,
    PageModeContextProps,
} from 'providers/pagemodeprovider'
import {
    PlayerQueueContext,
    PlayerQueueContextProps,
} from 'providers/playerqueueprovider'
import { Apps } from '../utils'
import { strollToElement, toAnchor } from 'utils/methods'

export const useLocalStorage = <T>(key: string, initialValue?: T) => {
    return storageHooks(key, window.localStorage, initialValue)
}

export const useSessionStorage = <T>(key: string, initialValue?: T) => {
    return storageHooks(key, window.sessionStorage, initialValue)
}

export const useMessages = (): MessageContextProps => {
    const context = useContext(MessageContext)
    if (!context) throw Error('No message context defined!')
    const { message, addError, addInfo, addSuccess, removeMessage } = context
    return { message, addError, addInfo, addSuccess, removeMessage }
}

export const useLoading = (): LoadingContextProps => {
    const context = useContext(LoadingContext)
    if (!context) throw Error('No loading context defined!')
    const { isLoading, setLoading } = context
    return { isLoading, setLoading }
}

export const useInternalAuthentication =
    (): InternalAuthenticationContextProps => {
        const context = useContext(InternalAuthenticationContext)
        if (!context) throw Error('No authentication context defined!')
        const { accessToken, isAuthenticated, logout } = context
        return { accessToken, isAuthenticated, logout }
    }

export const usePageMode = (): PageModeContextProps => {
    const context = useContext(PageModeContext)
    if (!context) throw Error('No page mode context defined!')
    const {
        pageMode,
        collectionState,
        setPageMode,
        saveCollectionState,
        resetCollectionState,
    } = context
    return {
        pageMode,
        collectionState,
        setPageMode,
        saveCollectionState,
        resetCollectionState,
    }
}

export const useActiveTrack = (): ActiveTrackContextProps => {
    const context = useContext(ActiveTrackContext)
    if (!context) throw Error('No active track context defined!')
    const { status, setStatus, activeTrack, playTrack, pauseTrack, stopTrack } =
        context
    return { status, setStatus, activeTrack, playTrack, pauseTrack, stopTrack }
}

export const useEnvironment = (): EnvironmentContextProps => {
    const context = useContext(EnvironmentContext)
    if (!context) throw Error('No environment context defined!')
    const { environment, activeEnvironments, activateEnvironment } = context
    return { environment, activeEnvironments, activateEnvironment }
}

export const useContentfulPreview = (): ContentfulPreviewContextProps => {
    const context = useContext(ContentfulPreviewContext)
    if (!context) throw Error('No contentful preview defined!')
    const { isPreview, setPreview } = context
    return { isPreview, setPreview }
}

export const usePlayerQueue = (): PlayerQueueContextProps => {
    const context = useContext(PlayerQueueContext)
    if (!context) throw Error('No player queue context defined!')
    const { queue, nextTrack, setQueue } = context
    return { queue, nextTrack, setQueue }
}

export const smallScreenWidth = 768

export const useCheckMobileScreen = () => {
    const [width, setWidth] = useState<number>(smallScreenWidth)

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    return [width < smallScreenWidth]
}

const storageHooks = <T>(key: string, storage: Storage, initialValue?: T) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from local storage by key
            const item = storage.getItem(key)
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            return initialValue
        }
    })
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to sessionStorage.
    const setValue = (value: T) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value
            // Save state
            setStoredValue(valueToStore)
            // Save to local storage
            if (value) {
                storage.setItem(key, JSON.stringify(valueToStore))
            } else {
                storage.removeItem(key)
            }
        } catch (error) {
            return
        }
    }
    return [storedValue, setValue] as const
}

export const useTabMenu = (defaultSelectedTab = 0) => {
    const [selectedTab, setSelectedTab] = useState(defaultSelectedTab)
    const handleMenuNav = (selectedTabIndex: number) => {
        setSelectedTab(selectedTabIndex)
    }
    const handleMenuNavCallback = useCallback(
        (selectedTabIndex: number) => handleMenuNav(selectedTabIndex),
        []
    )
    return [selectedTab, handleMenuNavCallback] as const
}

export const useFocus = () => {
    const htmlElRef = useRef<HTMLInputElement | null>(null)
    const setFocus = () => {
        const currentEl = htmlElRef.current
        currentEl && currentEl.focus()
    }
    return [htmlElRef, setFocus] as const
}

export const useRouter = (app: Apps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    switch (app) {
        case 'DevPortal':
            return usePortal()
        case 'Internal':
            return useInternal()
        default:
            return useLanding()
    }
}

export const useInternal = () => {
    const router = useNextRouter()

    const pushInternal = (url: string, as?: string) => {
        if (as) {
            router.push(`/internal${padSlash(url)}`, `/internal${padSlash(as)}`)
        } else {
            router.push(`/internal${padSlash(url)}`)
        }
    }

    return [pushInternal, router] as const
}

export const usePortal = () => {
    const router = useNextRouter()

    const pushPortal = (url: string, as?: string) => {
        if (as) {
            router.push(`/portal${padSlash(url)}`, `/portal${padSlash(as)}`)
        } else {
            router.push(`/portal${padSlash(url)}`)
        }
    }

    return [pushPortal, router] as const
}

export const useLanding = () => {
    const router = useNextRouter()

    const pushLanding = (url: string, as?: string) => {
        if (as) {
            router.push(`${padSlash(url)}`, `${padSlash(as)}`)
        } else {
            router.push(`${padSlash(url)}`)
        }
    }

    return [pushLanding, router] as const
}

export const useMenu = (tabs: TabListItemProps[], app: Apps = 'Internal') => {
    const [push, router] = useRouter(app)
    const findActiveTabIndex = useCallback(
        (pathname: string) =>
            tabs.findIndex(({ url, match }) =>
                match ? match.test(pathname) : url === pathname
            ),
        [tabs]
    )

    useEffect(
        () => setSelectedTab(findActiveTabIndex(router.pathname)),
        [router.pathname, findActiveTabIndex]
    )

    const [selectedTab, setSelectedTab] = useState(
        findActiveTabIndex(router.pathname)
    )
    const handleMenuNav = (idx: number) => {
        const selectedTab = tabs[idx]
        if (selectedTab.url) {
            push(selectedTab.url)
        } else if (selectedTab.onClick) {
            selectedTab.onClick()
        }
    }
    return [selectedTab, handleMenuNav] as const
}

export const useAnchor = () => {
    const [hash, setHash] = useState<string | undefined>()

    useEffect(() => {
        if (hash) {
            const timeout = setTimeout(() => {
                try {
                    const anchorElement = document.querySelector(toAnchor(hash))
                    strollToElement(anchorElement)
                } catch (e) {
                    // Invalid hash value!
                }
            }, 500)
            return () => clearTimeout(timeout)
        }
    }, [hash])

    return [setHash] as const
}

const Hooks = {
    useMessages,
}

export default Hooks
