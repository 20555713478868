import React, { FC, useCallback, useEffect, useState } from 'react'
import Environments from 'models/Environments'
import { EnvironmentType } from '../../utils'

export interface EnvironmentContextProps {
    environment: EnvironmentType
    activeEnvironments: EnvironmentType[]
    activateEnvironment: (environment: EnvironmentType) => void
}

export const EnvironmentContext = React.createContext<
    EnvironmentContextProps | undefined
>(undefined)

const EnvironmentProvider: FC<{
    children: React.ReactElement | JSX.Element
}> = ({ children }) => {
    const [environment, setEnvironment] = useState<EnvironmentType>()

    const activateEnvironment = (environment: EnvironmentType) => {
        Environments.setCurrent(environment)
        setEnvironment(environment)
    }

    useEffect(() => {
        if (!environment) {
            setEnvironment(Environments.getCurrentOrDefault())
        }
    })

    const contextValue = {
        environment,
        activeEnvironments: Environments.activeEnvironments,
        activateEnvironment: useCallback(
            (environment) => activateEnvironment(environment),
            []
        ),
    } as EnvironmentContextProps

    return (
        <EnvironmentContext.Provider value={contextValue}>
            {children}
        </EnvironmentContext.Provider>
    )
}

export default EnvironmentProvider
