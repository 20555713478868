import React, { useCallback, useState } from 'react'

export interface ContentfulPreviewContextProps {
    isPreview: boolean
    setPreview: (isPreview: boolean) => void
}

export const ContentfulPreviewContext = React.createContext<
    ContentfulPreviewContextProps | undefined
>(undefined)

interface ContentfulPreviewProviderProps {
    initialPreview: boolean
    children: React.ReactNode
}

const ContentfulPreviewProvider = ({
    initialPreview,
    children,
}: ContentfulPreviewProviderProps) => {
    const [isPreview, setPreview] = useState<boolean>(initialPreview)

    const contextValue = {
        isPreview,
        setPreview: useCallback((isPreview: boolean) => {
            setPreview(isPreview)
        }, []),
    } as ContentfulPreviewContextProps

    return (
        <ContentfulPreviewContext.Provider value={contextValue}>
            {children}
        </ContentfulPreviewContext.Provider>
    )
}

export default ContentfulPreviewProvider
