import React, { useCallback, useState } from 'react'
import { MetadataTrackResponse } from 'api/responses'
import { useActiveTrack } from 'hooks/hooks'

export interface PlayerQueue {
    name: string
    tracks: MetadataTrackResponse[]
}

export interface QueuedMetadataTrackResponse extends MetadataTrackResponse {
    queueName: string
}

export interface PlayerQueueContextProps {
    queue?: PlayerQueue
    nextTrack: (track?: QueuedMetadataTrackResponse) => void
    setQueue: (queue: PlayerQueue) => void
}

export const PlayerQueueContext = React.createContext<
    PlayerQueueContextProps | undefined
>(undefined)

interface PlayerQueueProviderProps {
    children: React.ReactNode
}

const PlayerQueueProvider = ({ children }: PlayerQueueProviderProps) => {
    const [queue, setQueue] = useState<PlayerQueue>()

    const { playTrack, activeTrack } = useActiveTrack()

    const nextTrack = (track?: MetadataTrackResponse) => {
        if (queue) {
            if (activeTrack && activeTrack.id === track?.id) {
                // This is the current track that was paused .
                // So we will just unpause.
                playTrack({ ...track, queueName: queue.name })
            } else if (track) {
                // Find the requested track!
                const trackIndex = queue.tracks.findIndex(
                    (t) => t.id === track.id
                )
                if (trackIndex !== -1) {
                    // Rebuild queue from that track!
                    const leftSide = queue.tracks.slice(0, trackIndex)
                    const rightSide = queue.tracks.slice(
                        trackIndex + 1,
                        queue.tracks.length
                    )
                    const nextTrack = queue.tracks[trackIndex]

                    playTrack({ ...nextTrack, queueName: queue.name })
                    setQueue({
                        name: queue.name,
                        tracks: rightSide.concat(leftSide).concat([nextTrack]),
                    })
                }
            } else {
                const tracks = [...queue.tracks]
                // No track given. Just play the next in queue!
                let nextActiveTrack = tracks.shift()
                if (nextActiveTrack) {
                    if (activeTrack && activeTrack.id === nextActiveTrack.id) {
                        // The active track is the same as the current.
                        // Skip one more and put last.
                        tracks.push(nextActiveTrack)
                        nextActiveTrack = tracks.shift()
                    }
                    if (nextActiveTrack) {
                        playTrack({ ...nextActiveTrack, queueName: queue.name })
                        // Put last!
                        setQueue({
                            name: queue.name,
                            tracks: [...tracks, nextActiveTrack],
                        })
                    }
                }
            }
        }
    }

    const contextValue = {
        queue,
        nextTrack,
        setQueue: useCallback((queue) => setQueue(queue), []),
    } as PlayerQueueContextProps

    return (
        <PlayerQueueContext.Provider value={contextValue}>
            {children}
        </PlayerQueueContext.Provider>
    )
}

export default PlayerQueueProvider
