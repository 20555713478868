import React, { useEffect, useState } from 'react'
import { AccessToken, AccessTokenType } from 'models/AccessTokens'
import Auth from 'api/auth'
import { useMessages } from 'hooks/hooks'
import { useOktaAuth } from '@okta/okta-react'
import { GENERAL_ERROR } from 'utils'

export interface InternalAuthenticationContextProps {
    accessToken: AccessToken | undefined
    isAuthenticated: boolean
    logout(): void
}

export const InternalAuthenticationContext = React.createContext<
    InternalAuthenticationContextProps | undefined
>(undefined)

interface AuthenticationProviderProps {
    children: React.ReactNode
    accessTokenType: string
}

const AuthenticationProvider = ({
    children,
    accessTokenType,
}: AuthenticationProviderProps) => {
    const [accessToken, setAccessToken] = useState<AccessToken>()
    const [isAuthenticated, setAuthenticatated] = useState<boolean>(false)
    const { addError } = useMessages()
    const { oktaAuth } = useOktaAuth()

    const logout = () => {
        setAccessToken(undefined)
        Auth.logout()
    }

    const contextValue = {
        isAuthenticated,
        accessToken,
        logout,
    }

    useEffect(() => {
        const persistedAccessToken = Auth.getAccessToken(accessTokenType)
        if (
            (!accessToken && persistedAccessToken) ||
            (accessToken && !persistedAccessToken)
        ) {
            setAccessToken(persistedAccessToken)
        }
        setAuthenticatated(accessToken !== undefined)
    })

    useEffect(() => {
        const interval = setInterval(() => {
            try {
                oktaAuth.authStateManager
                    .updateAuthState()
                    .then((authState) => {
                        if (authState.isAuthenticated) {
                            oktaAuth.tokenManager
                                .renew('accessToken')
                                .then(() => {
                                    const oktaToken = oktaAuth.getAccessToken()
                                    if (oktaToken) {
                                        const accessToken = new AccessToken(
                                            AccessTokenType.JWT,
                                            oktaToken
                                        )
                                        window.sessionStorage.setItem(
                                            AccessTokenType.JWT,
                                            JSON.stringify(accessToken)
                                        )
                                        setAccessToken(accessToken)
                                    }
                                })
                                .catch(() => {
                                    addError(GENERAL_ERROR)
                                })
                        }
                    })
                    .catch(() => {
                        addError(GENERAL_ERROR)
                    })
            } catch (e) {
                addError(GENERAL_ERROR)
            }
        }, 1800000)
        return () => clearInterval(interval)
    }, [])

    return (
        <InternalAuthenticationContext.Provider value={contextValue}>
            {children}
        </InternalAuthenticationContext.Provider>
    )
}

export default AuthenticationProvider
