import React, { useCallback, useState } from 'react'
import { CollectionInternalResponse } from 'api/responses'
import { Mode } from '../../utils'

export interface CollectionState {
    pageMode: Mode
    collections: CollectionInternalResponse[]
    selectedTab: number
}

export interface PageModeContextProps {
    pageMode: Mode
    collectionState: CollectionState
    setPageMode: (pageMode: Mode) => void
    saveCollectionState: (collectionState: CollectionState) => void
    resetCollectionState: () => void
}

export const PageModeContext = React.createContext<
    PageModeContextProps | undefined
>(undefined)

interface LoadingProviderProps {
    children: React.ReactNode
    initialMode: Mode
}

const PageModeProvider = ({ initialMode, children }: LoadingProviderProps) => {
    const [pageMode, setPageMode] = useState<Mode>(initialMode)
    const [collectionState, setCollectionState] = useState<CollectionState>()

    const saveCollectionState = useCallback(
        (collectionState: CollectionState) => {
            setCollectionState(collectionState)
        },
        []
    )

    const resetCollectionState = useCallback(() => {
        setCollectionState(undefined)
    }, [])

    const contextValue = {
        pageMode,
        collectionState,
        saveCollectionState,
        setPageMode,
        resetCollectionState,
    } as PageModeContextProps

    return (
        <PageModeContext.Provider value={contextValue}>
            {children}
        </PageModeContext.Provider>
    )
}

export default PageModeProvider
